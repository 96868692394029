import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addZone } from '../../../store/slices/zoneSlice';
import { useParams } from 'react-router-dom';
import './reportButton.css';
import {
  Grid,
  Stack,
  Typography,
  Card,
  Skeleton,
  LinearProgress,
  Modal,
  Box,
  Tooltip,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  Dialog,
  DialogContent
} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import DatePickerStore from './Calendar';
import LineChartToggle from './lineChartToggle';
import { IoMdSettings, IoMdDownload } from 'react-icons/io';
import { CgSpinner } from 'react-icons/cg';
import CsvModal from './CsvUpload';
import CsvModalAssociate from './CSV_Associate';
import RadarChart from './RadarChart';
import { GetPopPercentage, GetpopKPI, GetCapProgStoreView, GetAnomaliesCount, getAssociateScoreData, GetReport } from 'api';
import Chart from 'react-apexcharts';
import popIcon from '../../../assets/images/pop_icon.png';
import { FaCircleInfo } from 'react-icons/fa6';
import pog from '../../../assets/images/pog.jpeg';
import associate from '../../../assets/images/profile-user.png';

function Overview() {
  const urlParams = new URLSearchParams(window.location.search);
  const data = Object.fromEntries(urlParams.entries());
  const value = JSON.stringify(data).substring(2, 12);
  const storeID = JSON.stringify(data).substring(16, 20);
  console.log(storeID);

  const { store } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [totalPOP, setTotalPop] = useState(false);
  const [zoneWisePopData, setZoneWisePopData] = useState(false);
  const [associateScoreData, setAssociateScoreData] = useState([]);
  const [isGroup, setIsGroup] = useState([]);
  const [activeButton, setActiveButton] = useState('Trends View');
  const [isDownloading, setIsDownloading] = useState(false);
  // const [fileType, setFileType] = useState('excel');
  const [openModal, setOpenModal] = useState(false);
  const isNeoUser =
    JSON.parse(localStorage.getItem('userData')).data.number === '8826627310' ||
    JSON.parse(localStorage.getItem('userData')).data.number === '8369963636' ||
    JSON.parse(localStorage.getItem('userData')).data.number === '8249707748';
  const handleModal = () => {
    setOpenModal(!openModal);
  };
  const targetRef = useRef(null);
  const handleScrollToComponent = (zoneId) => {
    dispatch(addZone(zoneId)); //Add the zone id to store
    console.log('zoneId in overview page:', zoneId);
    setTimeout(() => {
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      handleButtonClick('Shelf View');
    }, 100);
  };

  const storeDetails = JSON.parse(localStorage.getItem('analysisStoreDetails'));

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  const customDate = new Date();
  const finalCustomDate = data
    ? value
    : [customDate.getFullYear(), padTo2Digits(customDate.getMonth() + 1), padTo2Digits(customDate.getDate())].join('-');
  const [date, setSelectedDate] = useState(finalCustomDate);
  const [openPopScoreModal, setOpenPopScoreModal] = useState(false);
  const [openAssociateScoreModal, setOpenAssociateScoreModal] = useState(false);
  const [snackbarConfig, setSnackbarConfig] = useState({ open: false, message: '', severity: 'success' });
  const [capProgressValue, setCapProgressValue] = useState(0);
  const [anomaliesCount, setAnomaliesCount] = useState([]);
  const [anomaliesLoading, setAnomaliesLoading] = useState(true);

  const handleClickAssociateScoreModal = () => {
    setOpenAssociateScoreModal((prev) => !prev);
    setIsSnackbarOpen(false);
  };
  const handleClickPopScoreModal = () => {
    setOpenPopScoreModal((prev) => !prev);
    setSnackbarConfig({ open: false, message: '', severity: 'success' });
    console.log(openPopScoreModal);
  };

  const handleClose = () => {
    setOpenPopScoreModal(false); // Close the modal
    setOpenAssociateScoreModal(false);
  };

  const handleUploadComplete = (success) => {
    //here changes are made, change it such that success is given as o/p only when both the API's give the response
    setOpenPopScoreModal(false); // Close the modal
    if (success) {
      setSnackbarConfig({ open: true, message: 'File uploaded successfully!', severity: 'success' });
    } else {
      setSnackbarConfig({ open: true, message: 'Error uploading file!', severity: 'error' });
    }
  };

  const handleCloseAssociateScoreModal = () => {
    setOpenAssociateScoreModal(false); // Close the modal
  };

  const handleAssociateUploadComplete = (success) => {
    //here changes are made, change it such that success is given as o/p only when both the API's give the response
    setOpenAssociateScoreModal(false); // Close the modal
    if (success) {
      setSnackbarConfig({ open: true, message: 'File uploaded successfully!', severity: 'success' });
    } else {
      setSnackbarConfig({ open: true, message: 'Error uploading file!', severity: 'error' });
    }
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ open: false, message: '', severity: 'success' });
  };

  const handleDownload = async (fileType) => {
    setIsDownloading(true);

    const selectedDate = new Date(date);
    selectedDate.setDate(selectedDate.getDate() + 1);
    const tomorrow = selectedDate.toISOString().split('T')[0];

    const startDate = date + 'T00:00:00';
    const endDate = tomorrow + 'T00:00:00';

    const body = {
      start_date: startDate,
      end_date: endDate,
      store_name: storeDetails.id,
      file_type: fileType
    };

    // Step 1: Create an instance of AbortController
    const abortController = new AbortController();
    const { signal } = abortController;

    try {
      //Step 2: Modify the GetReport call to accept the signal
      const response = await GetReport(body, { signal });
      console.log('Report Response:', response);

      if (response.data.presigned_url !== null) {
        const url = response.data.presigned_url;
        const link = document.createElement('a');
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSnackbarConfig({ open: true, message: 'File Downloaded Successfully', severity: 'success' });
      } else {
        setSnackbarConfig({ open: true, message: 'No data found !', severity: 'warning' });
      }
    } catch (error) {
      // Step 3: Optionally handle the AbortError
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        setSnackbarConfig({ open: true, message: 'Something went wrong !', severity: 'error' });
      }
    } finally {
      setIsDownloading(false);
    }

    // To abort the fetch call, you can call abortController.abort() from somewhere in your code.
    // Example: abortController.abort();
  };

  const accentColLight = theme.palette.success.light;
  const accentColDark = theme.palette.success.dark;
  const progressChart = {
    options: {
      chart: {
        height: 180,
        type: 'radialBar',
        sparkline: {
          enabled: true
        }
      },
      colors: [accentColLight],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            padding: 0,
            size: '30%'
            // background: '#293450'
          },
          track: {
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 4,
              opacity: 0.15
            }
          },
          dataLabels: {
            show: false,
            name: {
              offsetY: -10,
              color: '#fff',
              fontSize: '13px'
            },
            value: {
              color: '#fff',
              fontSize: '30px',
              show: false
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          gradientToColors: [accentColDark],
          stops: [0, 100]
        }
      },
      stroke: {
        // lineCap: 'round'
      }
      // labels: ['Progress']
    },
    series: [68]
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSmallScreen ? 300 : isMediumScreen ? 500 : 800,
    height: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '15px'
  };

  const [isMounted, setIsMounted] = useState(true);

  async function getPopPercentage(commonBody, signal) {
    try {
      const data = await GetPopPercentage(commonBody, { signal });
      if (!signal.aborted) {
        if (data.data !== null) {
          setTotalPop(parseFloat(data.data.average_pop_score).toFixed(1));
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        console.log(error);
        setTotalPop(false);
      }
    }
  }

  async function getZoneWisePopData(commonBody, signal) {
    try {
      const data = await GetpopKPI(commonBody, { signal });

      if (!signal.aborted) {
        if (data.data.length === 0) {
          setZoneWisePopData(false);
        } else if (data.data.length > 0) {
          const group = data.data;
          setIsGroup(group);

          group.forEach((item) => {
            let percentageString = item.data.FullnessPopPercent.replace('%', '');
            item.data.FullnessPopPercent = parseFloat(percentageString);
          });
          group.sort((a, b) => b.data.FullnessPopPercent - a.data.FullnessPopPercent);
          console.log('pxs');
          setZoneWisePopData(group);
        }
      }

      return data;
    } catch (error) {
      if (!signal.aborted) {
        setZoneWisePopData(false);
        setIsGroup([]);
        console.log(error);
      }
    }
  }

  async function getCaptureProg(commonBody, signal) {
    try {
      const data = await GetCapProgStoreView(commonBody, { signal });
      if (!signal.aborted) {
        if (data.data.length > 0) {
          setCapProgressValue(data.data[0].capture_percentage);
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        setCapProgressValue(0);
        console.log(error);
      }
    }
  }

  async function getAnomalies(commonBody, signal) {
    try {
      setAnomaliesLoading(true);
      const anomalies = await GetAnomaliesCount(commonBody, { signal });
      if (!signal.aborted) {
        if (anomalies) {
          setAnomaliesLoading(false);
          setAnomaliesCount(anomalies.data);
          console.log('def', anomalies.data);
          console.log('def date', date);
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        setAnomaliesLoading(true);
        setAnomaliesCount([]);
        console.log(error);
      }
    }
  }

  async function getAssociateScore(commonBody, signal) {
    try {
      const associateScore = await getAssociateScoreData(commonBody, { signal });
      if (!signal.aborted) {
        if (associateScore) {
          setAssociateScoreData(associateScore.data);
        }
      }
    } catch (error) {
      if (!signal.aborted) {
        setAssociateScoreData([]);
        console.log(error);
      }
    }
  }

  useEffect(() => {
    // Set the component to be mounted when the effect is run
    setIsMounted(true);

    // Return a cleanup function to set the component to unmounted
    return () => {
      setIsMounted(false);
    };
  }, []);
  // ------------------------

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    if (isMounted) {
      const commonBody = {
        date: date,
        store_id: store
      };

      getAnomalies(commonBody, signal);
      getPopPercentage(commonBody, signal);
      getZoneWisePopData(commonBody, signal);
      getCaptureProg(commonBody, signal);
      getAssociateScore(commonBody, signal);
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [date, isMounted]);

  return (
    <div className="w-full">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent={'space-between'}>
            <Typography variant="h3">Overview </Typography>
            {storeID ? <Typography variant="h6">Store ID: {storeID}</Typography> : <></>}
            <div className="flex space-x-2 sm:mt-2">
              <DatePickerStore SetSelectedDate={setSelectedDate} style={{ borderRadius: '15px' }} />
              <div>
                <button
                  onClick={isDownloading ? null : isNeoUser ? handleModal : () => handleDownload('excel')}
                  className="w-28 h-10 mt-5 md:mt-0 rounded-md shadow-md border border-white bg-cyan-500 hover:bg-cyan-600 active:bg-cyan-400 text-white flex place-items-center"
                >
                  {isDownloading ? (
                    <CgSpinner className="w-full text-xl animate-spin" />
                  ) : (
                    <div className="w-full h-full flex justify-center space-x-2">
                      <IoMdDownload className="h-full text-lg" />
                      <span className="mt-2 text-base">Report</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                {totalPOP ? (
                  <div className="flex  w-full  flex-col gap-1 p-3">
                    <div className="flex items-center justify-center gap-2 w-full">
                      {zoneWisePopData.length > 0 ? (
                        // <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" />
                        // icon here
                        <img src={popIcon} alt="pop" className="h-14 w-14" />
                      ) : (
                        <Skeleton variant="circular" width={60} height={45} />
                      )}
                      <div className="w-full">
                        {zoneWisePopData.length > 0 ? (
                          <p className="text-3xl">{totalPOP} %</p>
                        ) : (
                          <Skeleton variant="rectangular" className="mb-3 rounded-sm" width={50} height={20} />
                        )}

                        {zoneWisePopData.length > 0 ? (
                          <p className="text-lg font-semibold">PoP</p>
                        ) : (
                          <Skeleton variant="rectangular" width={150} height={15} className=" mb-2 rounded-sm" />
                        )}
                      </div>
                      <>
                        <IoMdSettings className="text-5xl cursor-pointer" onClick={handleClickPopScoreModal} />
                        <Modal
                          open={openPopScoreModal}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={modalStyle}>
                            <CsvModal onUploadComplete={handleUploadComplete} type={'popScore'} />
                          </Box>
                        </Modal>
                      </>
                    </div>
                    {zoneWisePopData.length > 0 ? (
                      <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                        {zoneWisePopData.map((item, index) => {
                          // const percentage = (item.totalCustomerZone / ftfall[0].totalCustomerZone) * 100;
                          const percentage =
                            item.data.FullnessPopPercent != undefined ? Math.round(parseFloat(item.data.FullnessPopPercent)) : 0;
                          const barcolor = percentage >= 99 ? '#00ac69' : percentage >= 95 ? '#f4a100' : '#ff413a';
                          // console.log(percentage);
                          return (
                            // <button
                            // key={index}
                            // onClick={handleScrollToComponent}
                            // className='flex w-full'

                            // >

                            <div onClick={() => handleScrollToComponent(item.zone_id)} className="mt-2 hover:cursor-pointer" key={index}>
                              <div className="flex gap-1 items-center">
                                {/* <div
                                  className=" rounded-full h-4 w-4"
                                  style={{
                                    backgroundColor: `${item.zoneColourHex}`
                                  }}
                                >
                                  {' '}
                                </div> */}
                                <div>
                                  {item.zone_id} :
                                  <span className="text-base font-semibold" style={{ color: barcolor }}>
                                    {' '}
                                    {percentage} %
                                  </span>
                                </div>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={percentage}
                                className="rounded-lg"
                                sx={{
                                  marginTop: '5px',
                                  backgroundColor: 'white', // Set color for unfilled part
                                  '& .MuiLinearProgress-bar': {
                                    backgroundColor: `${barcolor}` // Set color for filled part
                                  }
                                }}
                              />
                            </div>
                            // </button>
                          );
                        })}
                      </div>
                    ) : (
                      <Skeleton variant="rectangular" height={184} className="rounded-md" />
                    )}
                  </div>
                ) : (
                  <div className="flex  w-full  flex-col gap-1 p-3">
                    <div className="flex items-center justify-center gap-2 w-full">
                      <img src={popIcon} alt="pop" className="h-14 w-14" />
                      <div className="w-full">
                        <p className="text-3xl text-gray-500 ">NA</p>
                        <p className="text-lg font-semibold">PoP</p>
                      </div>
                      <>
                        <IoMdSettings className="text-5xl cursor-pointer" onClick={handleClickPopScoreModal} />
                        <Modal
                          open={openPopScoreModal}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={modalStyle}>
                            <CsvModal onUploadComplete={handleUploadComplete} type={'popScore'} />
                          </Box>
                        </Modal>
                      </>
                    </div>
                    <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                      <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                    </div>
                  </div>

                  // <img src={NoDataImg} style={{height:"100%", width:"100%"}} alt="No data" />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                <div className="flex  w-full  flex-col gap-1 p-3">
                  <div className="flex items-center justify-center gap-2 w-full">
                    {/* <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" /> */}
                    <img src={pog} alt="pop" className="h-14 w-14" />

                    <div className="w-full">
                      <p className="text-3xl text-gray-500 ">NA</p>
                      <p className="text-lg font-semibold">PoG</p>
                    </div>
                    <IoMdSettings className="text-5xl cursor-not-allowed" />
                  </div>
                  <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                    <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card className="border border-gray-300" sx={{ height: '276px' }}>
                <div className="flex  w-full  flex-col gap-1 p-3">
                  <div className="flex items-center justify-center gap-2 w-full">
                    {/* <DirectionsWalkIcon className="bg-[#444444] text-white rounded-full p-2 text-6xl" /> */}
                    <img src={pog} alt="pop" className="h-14 w-14" />

                    <div className="w-full">
                      <p className="text-3xl text-gray-500 ">NA</p>
                      <p className="text-lg font-semibold">OSA</p>
                    </div>
                    <IoMdSettings className="text-5xl cursor-not-allowed" />
                  </div>
                  <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                    <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <div style={{ height: '276px' }} className="flex flex-col">
                <Card className="border border-gray-300" sx={{ height: '276px' }}>
                  {associateScoreData.length > 0 ? (
                    <div className="flex  w-full  flex-col gap-1 p-3">
                      <div className="flex items-center justify-center gap-2 w-full">
                        <img src={associate} alt="pop" className="h-14 w-14" />
                        <div className="w-full">
                          <p className="text-3xl text-gray-500 ">NA</p>
                          <p className="text-lg font-semibold">Associate Score</p>
                        </div>
                        <>
                          <IoMdSettings className="text-5xl" onClick={handleClickAssociateScoreModal} />
                          <Modal
                            open={openAssociateScoreModal}
                            onClose={handleCloseAssociateScoreModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={modalStyle}>
                              <CsvModalAssociate onUploadComplete={handleAssociateUploadComplete} />
                            </Box>
                          </Modal>
                        </>
                      </div>
                      <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                        {associateScoreData.length > 0 ? (
                          associateScoreData.map((item, index) => {
                            const percentage =
                              Math.round(parseFloat(item.total_pop_percentage)) > 100
                                ? 100
                                : Math.round(parseFloat(item.total_pop_percentage));
                            const barcolor = percentage >= 99 ? '#00ac69' : percentage >= 95 ? '#f4a100' : '#ff413a';
                            const capturedZone = item.zones.map((i) => {
                              return i._id.zone;
                            });
                            const capturedZoneString = capturedZone.join(', ');
                            const assignedZone = item.assigned_zones;
                            const assignedZoneString = assignedZone ? assignedZone.join(', ') : '';
                            const firstScore =
                              parseFloat(item.total_pop_percentage_first).toFixed(1) > 100
                                ? 100
                                : parseFloat(item.total_pop_percentage_first).toFixed(1);
                            const secondScore =
                              parseFloat(item.total_pop_percentage).toFixed(1) > 100
                                ? 100
                                : parseFloat(item.total_pop_percentage).toFixed(1);
                            return (
                              <div className="mt-2" key={index}>
                                <div className="flex gap-1 items-center justify-between">
                                  <div>
                                    {item.name} :
                                    <span className="text-base font-semibold" style={{ color: barcolor }}>
                                      {' ' + percentage} %
                                    </span>
                                  </div>
                                  <Tooltip
                                    key={index}
                                    title={
                                      <div>
                                        <div className="mb-2 p-2">
                                          <p className="text-base">Assigned Zones</p>
                                          <p className="text-xs "> {assignedZoneString}</p>
                                        </div>
                                        <Divider
                                          sx={{
                                            bgcolor: 'white'
                                          }}
                                        />
                                        <div className="mb-2 p-2">
                                          <p className="text-base">Captured Zones</p>
                                          <p className="text-xs "> {capturedZoneString}</p>
                                        </div>
                                        <Divider
                                          sx={{
                                            bgcolor: 'white'
                                          }}
                                        />
                                        <div className=" p-2">
                                          <p className="text-xs">First Score :{' ' + firstScore} %</p>
                                          <p className="text-xs ">Second Score :{' ' + secondScore} %</p>
                                        </div>
                                      </div>
                                    }
                                  >
                                    <IconButton>
                                      <FaCircleInfo className="text-xs" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <LinearProgress
                                  variant="determinate"
                                  value={percentage}
                                  className="rounded-lg"
                                  sx={{
                                    marginTop: '5px',
                                    backgroundColor: 'white', // Set color for unfilled part
                                    '& .MuiLinearProgress-bar': {
                                      backgroundColor: `${barcolor}` // Set color for filled part
                                    }
                                  }}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <Skeleton variant="rectangular" height={184} className="rounded-md" />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex  w-full  flex-col gap-1 p-3">
                      <div className="flex items-center justify-center gap-2 w-full">
                        <img src={popIcon} alt="pop" className="h-14 w-14" />
                        <div className="w-full">
                          <p className="text-3xl text-gray-500 ">NA</p>
                          <p className="text-lg font-semibold">Associate Score</p>
                        </div>
                        <>
                          <IoMdSettings className="text-5xl" onClick={handleClickAssociateScoreModal} />
                          <Modal
                            open={openAssociateScoreModal}
                            onClose={handleCloseAssociateScoreModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={modalStyle}>
                              <CsvModalAssociate onUploadComplete={handleAssociateUploadComplete} type="associateStore" />
                            </Box>
                          </Modal>
                        </>
                      </div>
                      <div className=" bg-slate-100 flex-grow overflow-y-auto h-[184px] p-2 rounded-lg scrollbar">
                        <p className="text-base font-semibold text-gray-500">Currently No data available</p>
                      </div>
                    </div>
                  )}
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
              <Card
                className="border border-gray-300 h-full"
                sx={{
                  padding: '5px'
                }}
                style={{ height: '275px' }}
              >
                <RadarChart storeId={store} date={date} />
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid ref={targetRef} className="mb-10" item xs={12} lg={9} xl={9.6}>
              <Card className="border border-gray-300" sx={{ height: '550px' }}>
                <LineChartToggle
                  storeId={store}
                  date={date}
                  groups={isGroup}
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
              </Card>
            </Grid>
            <Grid item className="mb-10" xs={12} lg={3} xl={2.4}>
              <div className=" flex flex-col gap-2 " style={{ height: '275px' }}>
                <Card
                  className="border border-gray-300 h-2/3"
                  style={{
                    padding: '5px'
                  }}
                >
                  <div className="flex items-center">
                    <div>
                      <Chart
                        options={progressChart.options}
                        series={capProgressValue ? [parseFloat(capProgressValue).toFixed(1)] : [0]}
                        type={progressChart.options.chart.type}
                        height={progressChart.options.chart.height}
                      />
                    </div>
                    <div className="flex gap-1 flex-col">
                      <div className="text-4xl font-semibold">{capProgressValue ? parseFloat(capProgressValue).toFixed(1) : 0}%</div>
                      <div className="text-sm font-semibold">Capture Progress</div>
                    </div>
                  </div>
                </Card>
                <Card
                  className="border border-gray-300 bg-[#ff413a] h-1/3"
                  style={{
                    padding: '10px'
                  }}
                >
                  <div className="flex w-full h-full">
                    <div className="w-2/6 h-full flex flex-col">
                      <span className="text-center text-white text-sm font-semibold">Missing</span>
                      {!anomaliesLoading ? (
                        <span className="text-center text-white  flex-grow flex flex-col justify-center text-3xl font-semibold">
                          {anomaliesCount[0].totalMissingPopCount}
                        </span>
                      ) : (
                        <Skeleton variant="rectangular" height={184} className="rounded-md" />
                      )}
                    </div>
                    <div className="w-2/6 h-full flex flex-col border-2 border-t-0 border-b-0 border-l-white border-r-white">
                      <span className="text-center text-white  text-sm font-semibold">Alien</span>
                      {!anomaliesLoading ? (
                        <span className="text-center text-white  flex-grow flex flex-col justify-center text-3xl font-semibold">
                          {anomaliesCount[0].totalAlienPopCount}
                        </span>
                      ) : (
                        <Skeleton variant="rectangular" height={184} className="rounded-md" />
                      )}
                    </div>
                    <div className="w-2/6 h-full flex flex-col">
                      <span className="text-center text-white  text-sm font-semibold">Incorrect</span>
                      {!anomaliesLoading ? (
                        <span className="text-center text-white  flex-grow flex flex-col justify-center text-3xl font-semibold">
                          {anomaliesCount[0].totalIncorrectPopCount}
                        </span>
                      ) : (
                        <Skeleton variant="rectangular" height={184} className="rounded-md" />
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarConfig.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'bottom' + 'right'}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
      >
        <Alert onClose={handleSnackBarClose} severity={snackbarConfig.severity} sx={{ width: '100%' }}>
          {snackbarConfig.message}
        </Alert>
      </Snackbar>
      <Dialog open={openModal} onClose={handleModal}>
        <DialogContent>
          <div className="w-full h-full flex flex-col space-y-4 m-auto">
            <div className="m-auto text-5xl">{/* <AiOutlineExclamationCircle className="text-[#fb1751]" /> */}</div>
            <div id="poppinsFont" className="m-auto flex flex-col space-y-3">
              <span className="text-xl text-wrap text-center self-center">
                {' '}
                Please select the file type to download !
                {/* </span>
                        <span className="text-xl self-center"> */}
              </span>
              {/* <span className=" text-sm text-gray-600 self-center">(This process may take a minute)</span> */}
            </div>
            <Box sx={{ '& button': { m: 1 } }}>
              <div className="w-full flex justify-center">
                <button
                  className="reportBtn"
                  onClick={() => {
                    handleDownload('csv');
                    handleModal();
                  }}
                >
                  CSV
                </button>
                <button
                  className="reportBtn"
                  onClick={() => {
                    handleDownload('excel');
                    handleModal();
                  }}
                >
                  EXCEL
                </button>
              </div>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Overview;
