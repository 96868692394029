import { Button, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { Dialog, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import axios from 'axios';
import { UploadCSV } from '../../../api';

function CsvModal() {
  // Extract store parameter from URL
  const { store } = useParams();

  // State variables for managing the upload process
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [validationCheck, setValidationCheck] = useState(false);
  const [validateData, setValidateData] = useState([]);
  const [reupload, setReupload] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [files, setFiles] = useState('');

  // Debug log
  console.log(confirmationVisible, files);

  // Function to validate the uploaded file
  const Validation = async (data) => {
    console.log('Starting validation API call');
    try {
      const res = await axios.post(`https://nifno3du90.execute-api.eu-west-2.amazonaws.com/test/web-app/store-view/validate-pop`, data, {
        headers: {
          Accept: 'application/json'
        }
      });
      console.log('Validation API response:', res);
      return res;
    } catch (error) {
      console.error('Error during validation:', error);
      console.log('Validation error messages:', error.response.data.messages);
      setValidateData(error.response.data.messages);
    }
  };

  // Function to handle file upload process
  const handleFileUpload = async (file) => {
    console.log('File upload started:', file.name);

    // Check if the file is an .xlsx file
    if (!file.name.toLowerCase().endsWith('.xlsx')) {
      console.log('Invalid file type. Please upload an Excel (.xlsx) file.');
      setUploadError(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      console.log('File read successfully');
      const base64EncodedString = reader.result.split(',')[1];
      const fileName = file.name;
      const store_id = store;

      const payload = {
        body: base64EncodedString,
        file_name: 'gt_file'
      };

      console.log('Starting file validation');
      const val = await Validation(payload);
      console.log('Validation API response:', val);

      // Log more details about the validation response
      if (val && val.data) {
        console.log('Validation status code:', val.data.statusCode);
        console.log('Validation messages:', val.data.messages);
      }

      console.log('Validation check:', {
        statusCode: val?.data?.statusCode,
        messageType: val?.data?.messages?.[0]?.type,
        message: val?.data?.messages?.[0]?.message
      });

      // Check if validation passed
      if (
        val &&
        val.data &&
        val.data.statusCode === 200 &&
        val.data.messages &&
        val.data.messages.length > 0 &&
        val.data.messages[0].type === 'Success'
      ) {
        console.log('Validation passed, proceeding with upload');
        try {
          console.log('Attempting Excel upload');
          const res = await UploadCSV({ base64EncodedString, fileName, store_id });
          console.log('Excel upload response:', res);
          if (res.status === 200) {
            console.log('Excel upload successful');
            setUploadSuccess(true);
            setConfirmationVisible(true);
          } else {
            console.log('Excel upload failed');
            setUploadError(true);
          }
        } catch (error) {
          console.error('Error during upload:', error);
          setUploadError(true);
        }
      } else {
        console.log('Validation did not pass or returned unexpected result');
        setConfirmDialogOpen(true);
        setValidationCheck(true);
        setReupload(false);
        if (val && val.data && val.data.messages) {
          setValidateData(val.data.messages);
          console.log('Setting validation data:', val.data.messages);
        }
      }
      setLoading(false);
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      setLoading(false);
      setUploadError(true);
    };
    reader.readAsDataURL(file);
  };

  // Configure dropzone for file uploads
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDrop: async (acceptedFiles) => {
      handleFileSelection(acceptedFiles[0]);
    }
  });

  // New function to handle file selection (both for drag-and-drop and button click)
  const handleFileSelection = async (file) => {
    if (!file) return;

    console.log('File selected:', file.name);
    setLoading(true);
    setUploadError(false);
    setFiles(file);
    await handleFileUpload(file);
  };

  // Modify the handleReupload function
  const handleReupload = () => {
    setReupload(false);
    setValidationCheck(false);
    setUploadError(false);
    setUploadSuccess(false);
    open(); // This will open the file dialog
  };

  // Handle closing of confirmation dialog
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
    setReupload(true);
    setValidationCheck(false);
  };

  return (
    <div className="flex flex-col items-center gap-3 h-5/6 relative">
      {/* Dropzone area for file upload */}
      <div
        className="cursor-pointer w-full"
        style={{
          border: 'dashed',
          borderColor: '#4B5563',
          borderWidth: '2px',
          borderImage: 'none',
          borderImageSlice: 1,
          borderImageWidth: '1em'
        }}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className="h-72 bg-blue-100"
            style={{
              backgroundColor: isDragActive ? '#c1d4fb' : '#f3f4f6'
            }}
          >
            {isDragActive ? (
              <div className="flex flex-col gap-3 items-center justify-center h-72">
                <p className="text-xl font-semibold text-slate-500">Dropping files ...</p>
              </div>
            ) : (
              <div className="flex flex-col gap-3 items-center justify-center h-72">
                <p className="text-xl font-semibold text-slate-500">Drag and Drop files here</p>
                <FaCloudUploadAlt className="text-7xl" />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Upload button and status indicators */}
      <div className="flex gap-2">
        <Button variant="contained" component="label" disabled={loading || uploadSuccess} onClick={reupload ? handleReupload : open}>
          {loading ? (
            <CircularProgress size={24} />
          ) : reupload ? (
            'Reupload Excel'
          ) : validationCheck ? (
            // Dialog to display validation results
            <Dialog
              open={confirmDialogOpen}
              onClose={handleConfirmDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  borderRadius: '12px',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                  padding: '20px'
                }
              }}
            >
              <DialogTitle
                id="alert-dialog-title"
                style={{
                  paddingBottom: '10px',
                  color: '#333',
                  fontSize: '24px'
                }}
              >
                Validation Results
                <ImCross
                  onClick={handleConfirmDialogClose}
                  className="z-50 text-lg cursor-pointer text-black opacity-60 hover:opacity-100 absolute"
                  style={{
                    right: '20px',
                    top: '15px'
                  }}
                />
              </DialogTitle>
              <DialogContent style={{ color: '#555', fontSize: '20px' }} className="relative">
                {validateData && validateData.length > 0 ? (
                  validateData.map((data, index) => (
                    <div key={index}>
                      <DialogContentText id="alert-dialog-description" style={{ fontWeight: 'bold', fontSize: '22px' }}>
                        Type: {data.type}
                      </DialogContentText>
                      {/* Display missing columns */}
                      {data.type === 'Missing Columns' && data.missingColumns && (
                        <ul>
                          {data.missingColumns.map((column, idx) => (
                            <li key={idx}>{column}</li>
                          ))}
                        </ul>
                      )}
                      {/* Display type casting errors */}
                      {data.type === 'Type Casting Errors' && data.errors && (
                        <ul>
                          {data.errors.map((error, idx) => (
                            <li key={idx}>{error.message}</li>
                          ))}
                        </ul>
                      )}
                      {/* Display null or empty values */}
                      {data.type === 'Null or Empty Values' && data.details && (
                        <ul>
                          {Object.entries(data.details).map(([column, errors]) => (
                            <li key={column}>
                              {column}: {errors.map((error) => `Row ${error.index + 1}`).join(', ')}
                            </li>
                          ))}
                        </ul>
                      )}
                      {/* Display invalid bay IDs */}
                      {data.type === 'Invalid Bay IDs' && data.invalidBayIds && (
                        <div>
                          <ul>
                            {data.invalidBayIds.map((bayId, idx) => (
                              <li key={idx}>{bayId}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <DialogContentText>No messages to display</DialogContentText>
                )}
              </DialogContent>
            </Dialog>
          ) : uploadSuccess ? (
            <CheckCircleIcon />
          ) : uploadError ? (
            <ErrorIcon />
          ) : (
            'Upload Excel'
          )}
        </Button>
      </div>
    </div>
  );
}

export default CsvModal;
